import './template-styles/ArticlePage.scss'

import React from 'react'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import type { AboutWhichPageQuery } from '../../generated/frontend'
import { useAboutWhichPageQuery } from '../../generated/frontend'
import { AssociatedArticles, removeFalsy, removeUrlQueryStringAndFragment } from '../../shared'
import { useBackStack } from '../../shared/BrowserBackStackProvider'
import { BackToTop } from '../../shared/components/BackToTop/BackToTop'
import { Breadcrumb } from '../../shared/components/Breadcrumb/Breadcrumb'
import { ErrorComponent, PageError } from '../../shared/components/Error'
import { HideOnArticleScroll } from '../../shared/components/HideOnScroll/HideOnArticleScroll'
import { Loader } from '../../shared/components/Loader/Loader'
import { Redirect } from '../../shared/components/Redirect'
import { ResubscribeBanner } from '../../shared/components/ResubscribeBanner/ResubscribeBanner'
import { SharingOptions } from '../../shared/components/Sharing/SharingOptions'
import { getDataLayerScripts } from '../../shared/data-layer'
import { getDFPScripts } from '../../shared/dfp'
import { GridArticle, GridItemArticle } from '../../shared/grids'
import { useBaseMetaTags } from '../../shared/hooks/useBaseMetaTags'
import { useFullUrl } from '../../shared/hooks/useFullUrl'
import { articleBodyRenderer } from '../../shared/renderers/article-body-renderer'
import type { PageInfo } from '../../shared/types/PageInfo'
import { usePageProps } from '../../shared/usePageProps'
import { getUpdatedAtDate } from '../../shared/utils/get-updated-at-date'
import { getPreviousUrl } from '../../shared/utils/getPreviousUrl/getPreviousUrl'
import { PageWrapperWithMeta } from '../../shared/wrappers/PageWrapperWithMeta'
import { ArticleTemplateContext } from './ArticleTemplateContext'
import { JumpLinks } from './components'
import { Header } from './components/Header/Header'
import { dynamicTableDatalayer } from './dynamic-table-datalayer'
import styles from './template-styles/AboutWhichArticlePage.module.scss'
import { getPageComponentsText } from './utils/get-components-text'
import { generateSchema } from './utils/schema'

const AboutWhichArticlePage: React.FC = () => {
  const { pathname, search } = useLocation()
  const { context = '' } = usePageProps()
  const { loading, error, data } = useAboutWhichPageQuery({
    variables: { url: `${pathname}${search}`, context },
  })

  const { getFullUrl } = useFullUrl()
  const backStack = useBackStack()
  const previousUrl = getPreviousUrl({ backStack })

  const { getBaseMetaTags } = useBaseMetaTags()
  const resubscribeBannerFlag = useFeatureIsOn('resubscribe-banner')
  const { userAccessState } = usePageProps()
  const transformTypeDecision = userAccessState?.transformTypeDecision ?? ''
  const gbTestValue = useFeatureValue('ctx-011-contextualised-newsletter', '')

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (!data?.articlePage) {
    return <PageError pageName="Article Page" />
  }

  const { articlePage } = data

  if (articlePage.__typename === 'Redirect') {
    return <Redirect to={articlePage.redirectUrl} />
  }

  const {
    breadcrumb,
    meta,
    showResubBanner,
    articleBodyTemplate,
    header,
    jumpLinks,
    moreOnArticles,
    sharingData,
  } = articlePage

  const baseMetaTags = getBaseMetaTags({
    meta,
    context,
    twitterCard: 'summary_large_image',
  })
  const metaTags = getMetaTags({
    articlePage,
    baseMetaTags,
    urls: { currentUrl: getFullUrl(), previousUrl },
    gbTestValue,
  })

  const handleShowMoreOnJumplinkClick = () => {
    const showMoreBtn = document.querySelector<HTMLButtonElement>(
      '[data-testid="show-more-button"]'
    )

    if (showMoreBtn) {
      showMoreBtn.click()
    }
  }

  return (
    <ArticleTemplateContext.Provider value={{ meta }}>
      <div
        ref={domUpdates}
        data-testid="template-portal"
        data-template-type="about-which-article"
        className={styles.aboutWhichWrapper}
      >
        {renderResubscribeBanner(resubscribeBannerFlag, transformTypeDecision) && (
          <ResubscribeBanner showBanner={showResubBanner} />
        )}
        <Breadcrumb {...breadcrumb} />
        <PageWrapperWithMeta metaTags={metaTags}>
          <GridArticle>
            <GridItemArticle area="header">
              <Header {...header} />
            </GridItemArticle>
            <GridItemArticle area="jumplinks">
              <JumpLinks links={jumpLinks} handleOnClick={handleShowMoreOnJumplinkClick} />
            </GridItemArticle>
            <GridItemArticle area="share" className="gridarea-share">
              <HideOnArticleScroll>
                <SharingOptions sharingData={sharingData} />
              </HideOnArticleScroll>
            </GridItemArticle>
            <GridItemArticle area="body" className="gridarea-body">
              {articleBodyRenderer(articleBodyTemplate)}
              <div className="related-footer">
                <AssociatedArticles
                  componentType="more-on-this"
                  position="bottom"
                  {...moreOnArticles}
                  title="Additional links"
                />
              </div>
              <SharingOptions
                sharingData={sharingData}
                orientation="vertical"
                className={styles.sharingOptionsVertical}
              />
              <div className="back-to-top-wrapper">
                <BackToTop />
              </div>
            </GridItemArticle>
            {/* Side bar start */}
            <GridItemArticle area="related" className="gridarea-related">
              <aside className="sidebar">
                <AssociatedArticles
                  componentType="more-on-this"
                  position="right"
                  {...moreOnArticles}
                  title="Additional links"
                />
              </aside>
            </GridItemArticle>
            {/* sidebar end */}
          </GridArticle>
        </PageWrapperWithMeta>
      </div>
    </ArticleTemplateContext.Provider>
  )
}

export default AboutWhichArticlePage

const domUpdates = () => {
  dynamicTableDatalayer()
}

const renderResubscribeBanner = (featureFlag: boolean, transformTypeDecision: string): boolean => {
  if (
    !featureFlag ||
    !transformTypeDecision ||
    transformTypeDecision !== 'AUTHENTICATED_NO_ACCESS'
  ) {
    return false
  }

  return true
}

export type AboutWhichArticleData = AboutWhichPageQuery['articlePage'] & {
  __typename: 'ArticlePage'
}

const getMetaTags = ({
  articlePage: { meta, articleBodyTemplate },
  baseMetaTags,
  urls,
  gbTestValue,
}: {
  articlePage: AboutWhichArticleData
  baseMetaTags: JSX.Element[]
  urls: { currentUrl: string; previousUrl?: string }
  gbTestValue: string
}) => {
  const keywords = meta.keywords.length
    ? [<meta key="keywords" name="keywords" content={meta.keywords.join(', ')} />]
    : []

  const basePageInfo: PageInfo = {
    pageType: 'article',
    content_id: meta.contentId,
    keywords: meta.keywords,
    tags: meta.keywords,
  }

  // data layer
  const dataLayerPageInfo: PageInfo = {
    ...basePageInfo,
    content_type: meta.taxonomyHierarchy?.vertical?.slug === 'help' ? 'help' : 'about-which',
    wcdPageUrl: removeUrlQueryStringAndFragment(urls.currentUrl),
    ...(urls.previousUrl && { previousWcdPageUrl: urls.previousUrl }),
    keywords: meta.keywords?.length ? meta.keywords.join(' | ') : '',
    tags: meta.keywords?.length ? meta.keywords.join(' | ') : '',
    pageComponents: getPageComponentsText(articleBodyTemplate),
    contentPublishedDate: getUpdatedAtDate(meta.publishedDate),
    ...(meta.updatedDateTime && { contentUpdatedDate: getUpdatedAtDate(meta.updatedDateTime) }),
    systemContentPublishedDate: getUpdatedAtDate(meta.systemContentPublishedDate),
    systemContentUpdatedDate: getUpdatedAtDate(meta.systemContentUpdatedDate),
  }

  // data layer
  const dataLayer = getDataLayerScripts([...(meta.dataLayer || []), removeFalsy(dataLayerPageInfo)])

  // doubleclick for publishers
  const dfpMeta = getDFPScripts(meta.dataLayer, {
    ...basePageInfo,
    GB_test_value: gbTestValue,
    vertical: meta.taxonomyHierarchy?.vertical?.slug,
    sub_vertical: meta.taxonomyHierarchy?.subVertical?.slug,
  })

  // schema for SEO
  const schema = generateSchema({
    meta,
    authors: [],
    imageList: [],
  })

  return baseMetaTags.concat(
    keywords,
    dataLayer,
    dfpMeta,
    schema,
    <link rel="preconnect" href="https://securepubads.g.doubleclick.net" />
  )
}
